'use client'

import { Component, ReactComponentElement, MouseEvent, ReactNode } from 'react'
import { Cell, Text, Checkbox, Radio } from '@vinted/web-ui'
import { isFunction } from 'lodash'

import List from 'components/List'
import { SelectableListItem } from 'types/components'

type ListItemClickCallback<T, V> = (item: SelectableListItem<T, V>, event: MouseEvent) => void

export type RenderItemProps<T, V extends string | number = string | number> = {
  itemElementProps: ComponentProps<typeof Cell>
  isSelected: boolean
  item: SelectableListItem<T, V>
  button: ReactComponentElement<typeof Checkbox> | ReactComponentElement<typeof Radio>
}

type Props<T, V extends string | number = string | number> = {
  name: string
  items: Array<SelectableListItem<T, V>>
  selected: Array<V>
  onItemClick?: ListItemClickCallback<T, V>
  isMultiSelect: boolean
  renderItem?: (props: RenderItemProps<T, V>) => ReactNode
  itemButtonTestId?: string
  disableButtonClicks?: boolean
}

class SelectableItemList<T, V extends string | number = string | number> extends Component<
  Props<T, V>
> {
  static defaultProps = {
    items: [],
    selected: [],
    isMultiSelect: false,
    disableButtonClicks: false,
  }

  onItemClick = (item: SelectableListItem<T, V>) => (event: MouseEvent) => {
    const { disableButtonClicks, onItemClick } = this.props

    if (!(event.target instanceof HTMLAnchorElement) && !disableButtonClicks) {
      event.preventDefault()
    }

    if (isFunction(onItemClick)) onItemClick(item, event)
  }

  renderButton(item: SelectableListItem<T, V>) {
    const { name, selected, isMultiSelect, disableButtonClicks, itemButtonTestId } = this.props
    const { value } = item
    const componentProps = {
      name: isMultiSelect ? `${name}[]` : name,
      value,
      checked: selected.includes(value),
      aria: {
        'aria-labelledby': `${name}-list-item-${item.id}`,
      },
      testId: itemButtonTestId && `${itemButtonTestId}-${item.id}`,
      onChange: () => undefined,
    }

    let element = <Radio {...componentProps} propagateEvent={disableButtonClicks} />

    if (isMultiSelect) {
      element = <Checkbox {...componentProps} />
    }

    if (!disableButtonClicks) return element

    return <div className="u-no-pointer-events">{element}</div>
  }

  renderItem(item: SelectableListItem<T, V>) {
    const { name, selected, renderItem } = this.props
    const onClick = this.onItemClick.call(this, item)
    const button = this.renderButton.call(this, item)

    const itemElementProps = {
      id: `${name}-list-item-${item.id}`,
      key: item.id,
      type: Cell.Type.Navigating,
      body: <Text text={item.title} type={Text.Type.Title} as="span" />,
      onClick,
      suffix: button,
    }

    if (isFunction(renderItem)) {
      return renderItem({
        itemElementProps,
        isSelected: selected.includes(item.value),
        item,
        button,
      })
    }

    return <Cell {...itemElementProps} />
  }

  render() {
    const { items } = this.props

    return <List>{items.map(this.renderItem.bind(this))}</List>
  }
}

export default SelectableItemList
