'use client'

import { MouseEvent, useEffect, useState } from 'react'
import { Card, Cell, Icon, Image, useFloating, Text, Spacer } from '@vinted/web-ui'
import { Dropdown12 } from '@vinted/monochrome-icons'

import OutsideClick from 'components/OutsideClick'
import SelectableItemList from 'components/SelectableItemList'
import { RenderItemProps } from 'components/SelectableItemList/SelectableItemList'
import { CreditCardFormLocation, CreditCardType } from 'constants/credit-card'
import useTranslate from 'hooks/useTranslate'
import useTracking from 'hooks/useTracking'
import {
  clickEvent,
  singleCheckoutCoBrandedCardSelectionClickEvent,
  singleCheckoutCoBrandedCardSelectedEvent,
  singleCheckoutCoBrandedInfoModalFaqEvent,
} from 'libs/common/event-tracker/events'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { CreditCardBrandModel, CreditCardConfigurationModel } from 'types/models'

type Props = {
  isCobranded: boolean
  matchedCardConfig?: CreditCardConfigurationModel
  cardBrands: Array<CreditCardBrandModel> | null
  userSelectedCardBrand: CreditCardType | null
  entityId?: number | null
  orderId?: number
  onSelectedCardBrand: (creditCardType: CreditCardType | null) => void
  isSingleCheckout?: boolean
}

const CobrandedCardDropdown = ({
  isCobranded,
  matchedCardConfig,
  cardBrands,
  userSelectedCardBrand,
  onSelectedCardBrand,
  entityId,
  orderId,
  isSingleCheckout,
}: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('credit_card_add')

  const [selectedCardBrandIconUrl, setSelectedCardBrandIconUrl] = useState<string>()

  const [isOpen, setIsOpen] = useState(false)

  const { floaterRef, triggerRef, floaterStyle } = useFloating({
    isFloaterVisible: isOpen,
    shouldAutoUpdate: true,
    strategy: 'fixed',
    placement: 'bottom-end',
  })

  useEffect(() => {
    const cardBrandIconUrl = isCobranded
      ? cardBrands?.find(({ brand }) => brand === userSelectedCardBrand)?.iconUrl ||
        matchedCardConfig?.iconUrl
      : undefined

    setSelectedCardBrandIconUrl(cardBrandIconUrl)
  }, [cardBrands, isCobranded, userSelectedCardBrand, matchedCardConfig])

  const trackCobrandedFaqLinkClick = () => {
    if (isSingleCheckout && entityId) {
      track(
        singleCheckoutCoBrandedInfoModalFaqEvent({
          checkoutId: entityId.toString(),
          location: CreditCardFormLocation.Checkout,
        }),
      )

      return
    }

    track(
      clickEvent({
        screen: Screen.CreditCardAdd,
        target: ClickableElement.CobrandedModalInfo,
        targetDetails: JSON.stringify({
          transaction_id: entityId,
          service_order_id: orderId,
        }),
      }),
    )
  }

  const handleCobrandedFaqLinkClick = (event: MouseEvent) => {
    if (!(event.target instanceof HTMLAnchorElement)) return

    trackCobrandedFaqLinkClick()
  }

  const handleDropdownClose = () => setIsOpen(false)

  const trackCobrandedDropdownClick = () => {
    if (isSingleCheckout && entityId) {
      track(
        singleCheckoutCoBrandedCardSelectionClickEvent({
          checkoutId: entityId.toString(),
          location: CreditCardFormLocation.Checkout,
        }),
      )

      return
    }

    track(
      clickEvent({
        screen: Screen.CreditCardAdd,
        target: ClickableElement.CobrandedDropdown,
        targetDetails: JSON.stringify({
          transaction_id: entityId,
          service_order_id: orderId,
        }),
      }),
    )
  }

  const handleDropdownToggle = () => {
    trackCobrandedDropdownClick()
    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  const trackBrandSelection = (item: CreditCardType) => {
    if (isSingleCheckout && entityId) {
      track(
        singleCheckoutCoBrandedCardSelectedEvent({
          cardBrand: item,
          checkoutId: entityId.toString(),
          location: CreditCardFormLocation.Checkout,
        }),
      )

      return
    }

    track(
      clickEvent({
        screen: Screen.CreditCardAdd,
        target: ClickableElement.CobrandedModalSelection,
        targetDetails: JSON.stringify({
          transaction_id: entityId,
          service_order_id: orderId,
          selected_card_brand: item,
        }),
      }),
    )
  }

  const handleCardBrandClick = item => {
    setSelectedCardBrandIconUrl(item.data.iconUrl)
    onSelectedCardBrand(item.value)

    trackBrandSelection(item.value)
  }

  const renderIcon = (iconSrc?: string, isLargeIcon = false) => {
    if (!iconSrc) return null

    return <Image src={iconSrc} size={isLargeIcon ? Image.Size.Large : Image.Size.Medium} />
  }

  const renderCreditCardBrand = ({
    item,
    itemElementProps,
  }: RenderItemProps<{ iconUrl: string }>) => {
    if (!item.data) return null

    const { iconUrl } = item.data
    const icon = renderIcon(iconUrl, true)

    return <Cell {...itemElementProps} prefix={icon} />
  }

  const renderCobrandedCardDropdown = () => {
    return (
      <div
        className="u-zindex-large"
        style={{
          ...floaterStyle,
          maxWidth: 317,
        }}
        ref={floaterRef}
      >
        <Card styling={Card.Styling.Elevated}>
          <Cell
            body={
              <span role="none" onClick={handleCobrandedFaqLinkClick}>
                <Text
                  type={Text.Type.Subtitle}
                  width={Text.Width.Parent}
                  as="span"
                  text={translate('cobranded_card.note')}
                  html
                />
              </span>
            }
          />
          <div role="none" onClick={handleDropdownClose}>
            <SelectableItemList
              name="cobranded_cards"
              items={cardBrands?.map(({ title, brand, iconUrl }) => ({
                title,
                id: brand,
                value: brand,
                data: { iconUrl },
              }))}
              selected={userSelectedCardBrand ? [userSelectedCardBrand] : []}
              onItemClick={handleCardBrandClick}
              isMultiSelect={false}
              renderItem={renderCreditCardBrand}
            />
          </div>
        </Card>
      </div>
    )
  }

  const renderCobrandedCardTrigger = () => {
    return (
      <button
        type="button"
        onClick={handleDropdownToggle}
        ref={triggerRef}
        className="u-flexbox u-align-items-center"
      >
        {renderIcon(selectedCardBrandIconUrl)}
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.XSmall} />
        <Icon name={Dropdown12} color={Icon.Color.GreyscaleLevel3} />
      </button>
    )
  }

  const renderCardBrandSelect = () => {
    return (
      <OutsideClick onOutsideClick={handleDropdownClose}>
        <div className="u-position-relative">
          {renderCobrandedCardTrigger()}
          {isOpen && renderCobrandedCardDropdown()}
        </div>
      </OutsideClick>
    )
  }

  return (
    <div className="u-padding-top-large">
      {isCobranded ? renderCardBrandSelect() : renderIcon(matchedCardConfig?.iconUrl)}
    </div>
  )
}

export default CobrandedCardDropdown
