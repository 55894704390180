'use client'

import { Cell, Text, Image, Dialog, Button, Spacer } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTracking from 'hooks/useTracking'
import useTranslate from 'hooks/useTranslate'
import { clickEvent, creditCardAddClickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

type Props = {
  entityId?: number | string | null
  isSingleCheckout?: boolean
  show: boolean
  onConfirm: () => void
  onCancel: () => void
}

const CreditCardSaveConfirmationModal = ({
  show,
  entityId,
  isSingleCheckout,
  onCancel,
  onConfirm,
}: Props) => {
  const { track } = useTracking()
  const translate = useTranslate('credit_card_save_confirmation_modal')
  const asset = useAsset('/assets/credit-card-form')

  function trackDecision(save: boolean) {
    if (!entityId) return

    const target = save ? ClickableElement.SaveTheCard : ClickableElement.DontSaveTheCard

    if (isSingleCheckout) {
      track(
        creditCardAddClickEvent({
          target,
          checkoutId: entityId.toString(),
          screen: Screen.SaveCreditCardReminder,
        }),
      )

      return
    }

    track(
      clickEvent({
        target,
        screen: Screen.SaveCreditCardReminder,
        targetDetails: JSON.stringify({
          transaction_id: entityId,
        }),
      }),
    )
  }

  function handleConfirm() {
    trackDecision(true)
    onConfirm()
  }

  function handleCancel() {
    trackDecision(false)
    onCancel()
  }

  return (
    <Dialog show={show} testId="credit-card-save-confirmation-modal">
      <Cell>
        <div className="u-text-center">
          <Image src={asset('credit-card.svg')} size={Image.Size.XLarge} alt="" />
          <Spacer size={Spacer.Size.Large} />
          <Text
            text={translate('title')}
            type={Text.Type.Heading}
            testId="credit-card-save-confirmation-modal--title"
          />
        </div>
        <Spacer size={Spacer.Size.Large} />
        <Text
          text={translate('description')}
          testId="credit-card-save-confirmation-modal--description"
        />
        <Spacer size={Spacer.Size.XLarge} />
        <Button
          text={translate('actions.confirm')}
          styling={Button.Styling.Filled}
          onClick={handleConfirm}
          testId="credit-card-save-confirmation-modal--confirm-button"
        />
        <Spacer />
        <Button
          styling={Button.Styling.Flat}
          text={translate('actions.cancel')}
          onClick={handleCancel}
          testId="credit-card-save-confirmation-modal--cancel-button"
        />
      </Cell>
    </Dialog>
  )
}

export default CreditCardSaveConfirmationModal
